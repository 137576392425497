<template>
    <el-cascader
        :value="data[item.key]"
        :options="options"
        filterable
        :props="item.props || {}"
        :show-all-levels="false"
        @change="handleChange"
    ></el-cascader>
</template>

<script>
export default {
    data() {
        return {
            options: []
        };
    },
    props: {
        item: {
            type: Object,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        level() {
            return this.item.level;
        }
    },
    mounted() {
        if (this.item.remote) {
            this.item.remote().then(data => {
                this.options = data || [];

                /**
                 * 专为商品管理只有二级分类而作
                 */
                this.options.map(it => {
                    if (!it[this.item.props.children]) {
                        it[this.item.props.children] = [];
                    }
                });
            });
        } else {
            this.options = this.item.options || [];
        }
    },
    methods: {
        handleChange(value) {
            console.log(value);
            this.$set(this.data, this.item.key, value);
        }
    }
};
</script>